import React, { useEffect, useState } from 'react'
import { DatePicker, Select, Table } from 'antd'
import calender from "../../../../../../assets/images/calender-week.svg"
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import dayjs from 'dayjs';
import Loader from '../../../../../../components/Loader/Loader';
import { useSelector } from 'react-redux';

const AssetCollect = (props: any) => {
  const employeeDetails: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const { assertDetails } = props;
  const editAssertDetails = useGetApiRequests('editDeprovisonAssert', 'PUT');
  const [assertData, setAssertData] = useState<any>([]);
  const [loader, setLoader] = useState(false)
  const dropDownList = [
    { value: 38, label: 'Received' },
    { value: 18, label: 'Not Received' }
  ]
  useEffect(() => {
    setLoader(true);


    setAssertData([{
      assertName: employeeDetails.deviceType,
      otherPeripherals: employeeDetails.otherPeripherals || "-",
      status: "",
      statusId: "",
      returnedDate: "",
      employeeAssetId: ""

    }])
    setLoader(false);

  }, [assertDetails])

  const relievingDateOnSelect = (dateString: any, index: number) => {
    const shallowAssertArray: any = [...assertData];
    shallowAssertArray[index].returnedDate = dateString;
    setAssertData(shallowAssertArray)
  };

  const handleChange = (record: any, value: any, index: number) => {
    if (record?.returnedDate !== null && record?.returnedDate !== undefined && record?.returnedDate !== '') {
      const queryParams: any = {
        assertReceivedDate: record?.returnedDate,
        status: value,
      };
      const pathParams: any = {
        id: `${record?.employeeAssetId}status`
      }
      const shallowAssertArray: any = [...assertData];
      shallowAssertArray[index].statusId = value;
      shallowAssertArray[index].status = value === 38 ? 'Received' : 'Not Received';

      editAssertDetails('', queryParams, pathParams).then((res: any) => {
        setAssertData(shallowAssertArray)

      })
        .catch((err: any) => triggerNotification('error', '', err?.response?.data?.message, 'topRight'))
    } else {
      triggerNotification('error', '', ErrorMessages?.selectReturnDate, 'topRight')
    }

  };

  const columns = [
    {
      title: 'Asset Name',
      dataIndex: 'assertName',
      key: 'assertName',
      status: "collected",
      width: "20%",

    },
    {
      title: 'Other Peripherals',
      dataIndex: 'otherPeripherals',
      key: 'otherPeripherals',
      width: "40%"

    },
    {
      title: 'Return Date',
      dataIndex: 'date',
      key: 'date',
      render: (text: any, record: any, index: number) => (
        <div className='cursor-pointer'>

          <div className='justify-center items-center py-2 ' >
            {record?.returnedDate !== null && record?.returnedDate !== '' ? (
              <DatePicker
                placeholder='Select Date'
                suffixIcon={<img src={calender} />}
                className='float-control w-[70%]'
                name="resignationDate"
                format="YYYY-MM-DD"
                value={dayjs(record?.returnedDate)} onChange={(date, dateString) => { relievingDateOnSelect(dateString, index) }} />) :
              (
                <DatePicker
                  placeholder='Select Date'
                  suffixIcon={<img src={calender} />}
                  className='float-control w-[70%]'
                  name="resignationDate"
                  format="YYYY-MM-DD"
                  onChange={(date, dateString) => { relievingDateOnSelect(dateString, index) }} />
              )}
          </div>
        </div>),
      width: "25%"

    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: "20%",
      render: (text: any, record: any, index: number) => (
        <div className='cursor-pointer'>
          <Select
            style={{ width: 150 }}
            value={record?.status === 'Allocated' ? 'Not Received' : record?.status}
            suffixIcon={<span className="material-symbols-outlined"> expand_more </span>}
            onChange={(value) => handleChange(record, value, index)}
            options={dropDownList}
          /></div>
      ),

    },
  ];
  return (

    <div className='asset-collection pt-10 '>

      <h3 className='text-[#D34A7C] text-[15px] font-semibold'>ASSET ISSUED</h3>
      {assertData && (
        <Table dataSource={assertData} columns={columns} pagination={false} />)}
      {loader && <Loader />}
    </div>
  )
}

export default AssetCollect
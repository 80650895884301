import React, { useEffect, useState } from 'react';
import { DatePicker, Modal, } from 'antd';
import FloatLabel from '../../../../../components/FloatLabel';
import AddSalaryComponent from './salary-component/AddSalaryComponent';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
const AddSalaryModal = (props: any) => {
  const { open, employeeSalaryId, handleClose, isEdit, grossSalary, costToCompany, netAmount, salaryParticulars, employeeContribution, employerContribution, FixedSalaryParticulars, FixedEmployerContribution, employeeSalaryGeTApi, isRevised, historyView, revisedOnDate } = props;
  const { search } = useLocation();
  const empId: any = new URLSearchParams(search).get("employeeTypeId")
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState('');
  const { id } = useParams();
  const dateFormat = 'YYYY-MM-DD';
  const getCurrentDate = () => {
    const now = dayjs();
    const currentdate = now.format('YYYY-MM-DD');
    setDate(currentdate);
  }
  useEffect(() => {
    setModalOpen(open);
    if (!historyView) {
      getCurrentDate()
    } else {
      setDate(revisedOnDate)
    }
  }, [open])
  const handleCloseModal = () => {
    setModalOpen(false);
    handleClose();
  };


  const onChange = (date: any, dateString: string) => {
    setDate(dateString)
  };
  const changeNumberToIndianCurrency = (numberValue: any) => {
    return Number(numberValue).toLocaleString('en-IN');
  }

  return (
    <Modal title='Salary Particulars' className='salary-modal' centered open={modalOpen} onCancel={handleCloseModal} footer={null} maskClosable={false}>
      <div className="salary-date flex justify-end">
        {isRevised || isEdit ? (
          <div className='form-item basicForm-fields mb-2 mt-2 mr-10'>
            <FloatLabel label="Revised on" value={date}>
              <DatePicker disabled={historyView} className='float-control' placeholder='' onChange={onChange} defaultValue={dayjs(date, dateFormat)} format="YYYY-MM-DD" />
            </FloatLabel>
          </div>
        ) : null}
      </div>
      <AddSalaryComponent historyView={historyView} isEdit={isEdit} employeeSalaryId={employeeSalaryId} empTypeId={empId} handleCloseModal={handleCloseModal} IndianCurrencyFormat={changeNumberToIndianCurrency} date={date} employeeId={id} salaryParticularsProp={salaryParticulars} employeeContributionProp={employeeContribution} employerContributionProp={employerContribution} grossSalary={grossSalary} costToCompany={costToCompany} netAmount={netAmount} fixedSalaryParticularsProp={FixedSalaryParticulars} fixedEmployerContributionProp={FixedEmployerContribution} employeeSalaryGeTApi={employeeSalaryGeTApi} isRevised={isRevised} />
    </Modal>
  )
}
export default AddSalaryModal

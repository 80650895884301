import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import onboard from "../../../assets/images/logo.png"
import './DesignationApproval.scss'
import Loader from '../../../components/Loader/Loader';


const DesignationApproval = () => {
  const desigantionApprovalUrl = useGetApiRequests('desigantionApprovalUrl', 'PUT');
  const bonusApprovalUrl = useGetApiRequests('approveBonus', 'PUT');
  const approveLeaveApi = useGetApiRequests('approveLeave', 'PUT');
  const tokenValidation = useGetApiRequests('tokenValidation', 'GET');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const employeeId = searchParams.get('employeeId');
  const employeeApiKey = searchParams.get('employeeApiKey');

  const token = searchParams.get('token');
  const designationId = searchParams.get('designationId');
  const designationEffectiveFrom: any = searchParams.get('designationEffectiveFrom');
  const departmentId = searchParams.get('departmentId');
  const status = searchParams.get('status');
  const date = new Date(designationEffectiveFrom);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
  const [approvedMessage, setapprovedMessage] = useState<any>(null)
  const [alreadySubmitted, setAlreadySubmitted] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(true)

  useEffect(() => {
    const queryString = window.location.search;
    // getAllApiData(employeeApiKey)

    if (window.location.href.includes('/employee-bonus-update-status/')) {
      const urlParams = new URLSearchParams(queryString);
      const queryParams: any = {};
      urlParams.forEach((value, key) => {
        queryParams[key] = value;
      });
      handleBonusApproveMail(queryParams)

    }

    else if (window.location.href.includes('/leave-request-update-status/')) {

      const urlParams = new URLSearchParams(queryString);
      const queryParams: any = {};
      urlParams.forEach((value, key) => {
        queryParams[key] = value;
      });
      delete queryParams.employeeApiKey;
      queryParams.employeeLeaveRequestId = parseInt(queryParams.employeeLeaveRequestId, 10);
      queryParams.duration = parseInt(queryParams.duration, 10);
      queryParams.startDate = convertDateToISO(queryParams.startDate)
      queryParams.endDate = convertDateToISO(queryParams.endDate)
      approvedLeaveApi(queryParams)
    }
    else
      approvalMail()
  }, [])


  const getAllApiData = async (employeeApiKey: any) => {
    const queryParams = { employeeApiKey: employeeApiKey }
    try {
      setLoader(true)
      const [employeeTokenValidation]: any = await Promise.all([
        tokenValidation('', queryParams),
      ]);

      setLoader(false)
    } catch (error) {
      setLoader(false)
      triggerNotification('error', '', "Seems like you have already filled the personal info", 'topRight');
      setTimeout(() => {
        window.close();
      }, 3000)
    }
  };


  function convertDateToISO(dateString: any) {
    var parts = dateString.split(".");
    var formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    var isoDate = `${formattedDate}T00:00:00.000+00:00`;
    return isoDate;
  }


  const approvedLeaveApi = (argument: any) => {

    approveLeaveApi(argument)
      .then((res: any) => {
        setAlreadySubmitted(false)
        setapprovedMessage(res?.data)
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        setAlreadySubmitted(true)
      })
  }

  const handleBonusApproveMail = (argument: any) => {
    const pauload = {
      employeeBonusId: parseInt(argument?.employeeBonusId),
      employeeId: parseInt(argument?.employeeId),
      employeeApiKey: argument?.employeeApiKey,
      status: argument?.STATUS,
      bonusType: argument?.bonusType
    }
    const pathParam = { id: argument?.employeeBonusId, endPoint: 'approve' }
    bonusApprovalUrl('', pauload, pathParam)
      .then((res: any) => {
        setAlreadySubmitted(false)
        setapprovedMessage(res?.data)
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        setAlreadySubmitted(true)
      })
  }
  const approvalMail = () => {
    let requestPayload;
    if (departmentId) {
      let payload = {
        designationEffectiveFrom: formattedDate,
        designationId: designationId,
        departmentId: departmentId,
      };
      requestPayload = payload
    }
    else {
      let payload = {
        designationEffectiveFrom: formattedDate,
        designationId: designationId,
      };
      requestPayload = payload
    }
    const query = {
      "employeeApiKey": token,
      "statusName": status
    }
    const pathParam = {
      "employeeId": employeeId,
    }

    desigantionApprovalUrl(requestPayload, query, pathParam)
      .then((res: any) => {
        if (res) {
          setAlreadySubmitted(false)
          setapprovedMessage(res?.data)
          setLoader(false)
        }
      })
      .catch((err: any) => {
        setLoader(false)
        setAlreadySubmitted(true)
      });
  }
  return (
    <>
      {loader && <Loader />}
      <section className="approved-screen">
        <div className="loader-block">
        </div>
        <div className="header__logo" >
          <img src={onboard} alt="OnboardingLogo" />
          <span>ALL-Aboard!</span>
        </div>
        {alreadySubmitted ? (<div className="card"><p><span>Seems Like Already Submitted</span></p></div>) : (
          <div className="card">
            {approvedMessage?.status?.message === 'Designation Approved Successfully' ||
              approvedMessage?.status?.message === 'Employee Bonus Approved Successfully' ||
              approvedMessage?.message === 'Leave approved successfully' ? (
              <>
                <p><span className="material-symbols-outlined">check_circle</span> </p>
                <p>Approved </p>
              </>
            ) : (
              <>
                <p> <span className="rejected-designation material-symbols-outlined rejected">cancel</span></p>
                <p>Cancel</p>
              </>
            )}
          </div>
        )}
      </section >
    </ >
  )
}

export default DesignationApproval

import { APIConfigURLs } from '../apiInterfaceType/apiInterfaceType';

const PUT_API_URL: APIConfigURLs = {
    editDepartment: {
        url: '/hrm/department',
    },
    editUsers: {
        url: '/hrm/user',
    },
    editOrganisation: {
        url: '/hrm/organisation',
    },
    companyEmployeeDetails: {
        url: '/hrm/employee/company-employee-details',
    },
    saveSalaryMaster: {
        url: '/hrm/salarycomponent',
    },
    updateEmployeeSalary: {
        url: '/hrm/employee/salary',
    },
    editRole: {
        url: '/hrm/role',
    },
    desigantionApprovalUrl: {
        url: '/hrm/designation/designation-status',
    },
    editDesignation: {
        url: '/hrm/designation',
    },
    updateReviewAppraisal: {
        url: '/hrm/review',
    },
    updateBonusDetails: {
        url: '/hrm/employee-bonus',
    },
    editChapter: {
        url: '/chapter',
        baseURL: '/elms-app-api/v1',
    },
    updateQuizInChapters: {
        url: '/quiz/',
        baseURL: '/elms-app-api/v1',
    },
    updateCourse: {
        url: '/course',
        baseURL: '/elms-app-api/v1',
    },
    approveBonus: {
        url: '/hrm/employee-bonus',
    },
    updateLeaveRequest: {
        url: '/leave-request',
        baseURL: '/admin/leave-management/v1',
    },
    editLeavePolicy: {
        url: '/leave/policy/integration',
        baseURL: '/admin/leave-management/v1',
    },
    editHoliday: {
        url: '/holiday-detail',
        baseURL: '/admin/leave-management/v1',
    },
    paymentSave: {
        url: '/hrm/payment/',
    },
    editDeprovisionList: {
        url: '/hrm/employee/deprovision',
    },
    editDeprovisonAssert: {
        url: '/hrm/asset',
    },
    editLearningPathName: {
        url: '/learning-path',
        baseURL: '/elms-app-api/v1',
    },
    editLearningPathLevel: {
        url: '/learning-path-level/learning-path',
        baseURL: '/elms-app-api/v1',
    },
    deprovisionApproval: {
        url: '/employee/deprovision',
        baseURL: '/elms-app-api/v1',
    },
};

export default PUT_API_URL;

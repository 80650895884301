const checkValueGreaterThanZero = (value: any) => {
    if (Number(value) > 0) {
        return Number(value);
    }
    return 0;
};
export const calculateSalaryParticulars = (salaryDetails: any, salaryParticularsData: any) => {
    const updatedSalaryParticulars = salaryParticularsData.map((item: any) => {
        salaryDetails.employeeSalaryDetails.forEach((salary: any) => {
            if (item.salaryComponentId === salary.salaryComponentId) {
                item.monthly = Math.round(Number(salary.monthly));
                item.yearly = Math.round(Number(salary.yearly));
            }
        });
        return item;
    });
    return updatedSalaryParticulars;
};
export const calculateEmployerContribution = (salaryDetails: any, employerContributionData: any) => {
    let tempBasicSalaryMonthly = 0;
    const updatedEmployerContribution = employerContributionData.map((item: any) => {
        salaryDetails.employeeSalaryDetails.forEach((salaryComponent: any) => {
            if (item.salaryComponentId === salaryComponent.salaryComponentId) {
                if (item.name === 'Gratuity') {
                    item.monthly = Math.round(Number(salaryComponent.monthly));
                    item.yearly = Math.round(Number((tempBasicSalaryMonthly * 15) / 26));
                } else {
                    item.monthly = Math.round(Number(salaryComponent.monthly));
                    item.yearly = Math.round(Number(salaryComponent.yearly));
                }
            }
        });
        return item;
    });
    return updatedEmployerContribution;
};
export const calculateEmployeeContribution = (salaryDetails: any, employeeContributionData: any) => {
    const updatedEmployeeContribution = employeeContributionData.map((item: any) => {
        salaryDetails.employeeSalaryDetails.forEach((salary_Component: any) => {
            if (item.salaryComponentId === salary_Component.salaryComponentId) {
                item.monthly = Math.round(Number(salary_Component.monthly));
                item.yearly = Math.round(Number(salary_Component.yearly));
            }
        });
        return item;
    });
    return updatedEmployeeContribution;
};
export function calculateGrossMonthlyYearly(orgId: number, salaryDetails: any, updatedSalaryParticulars: any) {
    let grossAmountMonthly = 0;
    let grossAmountAnnual = 0;
    let isLTAEligible = orgId !== 563 && salaryDetails.ctc > 400000;
    updatedSalaryParticulars.forEach((obj: any) => {
        if (obj.monthly !== '' && obj.yearly !== '') {
            if (obj.name === 'LTA') {
                if (isLTAEligible) {
                    grossAmountMonthly += Math.round(Number(obj.monthly));
                    grossAmountAnnual += Math.round(Number(obj.yearly));
                }
            } else {
                grossAmountMonthly += Math.round(Number(obj.monthly));
                grossAmountAnnual += Math.round(Number(obj.yearly));
            }
        }
    });
    return { grossAmountMonthly, grossAmountAnnual };
}
export function calculationForCtc(salaryDetails: any, updatedEmployerContribution: any) {
    let monthlyCtc;
    let tempCtcAnnual = Number(salaryDetails.ctc);
    updatedEmployerContribution.forEach((item: any) => {
        if (Number(item.monthly) === 0 && Number(item.yearly) !== 0) {
            tempCtcAnnual -= Number(item.yearly);
        }
    });

    monthlyCtc = Math.round(Number(tempCtcAnnual) / 12);
    return monthlyCtc;
}
export function calculationForNetAmount(updatedEmployeeContribution: any, grossAmountMonthly: any, grossAmountAnnual: any, salaryDetails: any) {
    let netAmountMonthly = 0;
    let netAmountAnnual = 0;

    let employeeContributionMonthly = 0;
    let employeeContributionYearly = 0;

    const employeeContributionList = [...updatedEmployeeContribution];

    employeeContributionList.forEach(obj => {
        if (obj.monthly !== '') {
            employeeContributionMonthly += Number(obj.monthly);
        }
        if (obj.yearly !== '') {
            employeeContributionYearly += Number(obj.yearly);
        }
    });

    netAmountMonthly = Math.round(grossAmountMonthly - employeeContributionMonthly);
    netAmountAnnual = Math.round(grossAmountAnnual - employeeContributionYearly);
    return { netAmountMonthly, netAmountAnnual };
}
export function calculationForTotalAmount(salaryDetails: any, grossAmountAnnual: any, updatedEmployeeContribution: any, updatedSalaryParticulars: any) {
    let totalAnnual = 0;
    let totalMonthly = 0;

    const ctcAmount = Number(salaryDetails.ctc);

    if (ctcAmount && updatedEmployeeContribution && updatedSalaryParticulars) {
        const sumEmployerContributionAmount = updatedEmployeeContribution.reduce((sum: any, obj: any) => sum + Number(obj.yearly), 0);
        const tempProAmount = grossAmountAnnual + sumEmployerContributionAmount;
        const remainAmount = ctcAmount - tempProAmount;

        if (remainAmount >= 0) {
            totalAnnual = tempProAmount + remainAmount;
        } else {
            const tempRemainAmount = -1 * remainAmount;
            totalAnnual = tempProAmount + tempRemainAmount;
        }

        totalMonthly = totalAnnual / 12;
    }
    return { totalAnnual, totalMonthly };
}
export const calculateFixedSalaryParticulars = (apiresponse: any) => {
    const tempFixedSalaryParticulars = apiresponse
        .filter((obj: any) => obj.groupName && obj.groupName.trim() === 'Salary Particulars' && (obj.percent === 0 || obj.percent == null))
        .map((obj: any) => {
            return Object.assign(obj, {
                monthly: obj.sectionName === 'monthly' ? Math.round(Number(obj.amount)) : Math.round(Number(obj.amount / 12)),
                yearly: obj.sectionName === 'yearly' ? Math.round(Number(obj.amount)) : Math.round(Number(obj.amount * 12)),
            });
        });
    return tempFixedSalaryParticulars;
};

export const calculateFixedEmployerContribution = (apiResponse: any) => {
    const tempFixedEmployerContribution = apiResponse.filter((obj: any) => {
        if (obj.groupName && obj.groupName.trim() === 'Employer Contribution' && (obj.percent === 0 || obj.percent === null)) {
            return Object.assign(obj, {
                monthly: obj.sectionName === 'monthly' ? Math.round(Number(obj.amount)) : Math.round(checkValueGreaterThanZero(0)),
                yearly: obj.sectionName === 'yearly' ? Math.round(Number(obj.amount)) : Math.round(Number(obj.amount * 12)),
            });
        }
    });
    return tempFixedEmployerContribution;
};

export const gratuityCalculation = (salaryParticular: any, employerContribution: any) => {
    const basicSalaryMonthly: any = salaryParticular;
    const gratuityYearly = (basicSalaryMonthly * 15) / 26;
    const gratuityIndex = employerContribution.findIndex((item: any) => item.name === 'Gratuity');
    if (gratuityIndex !== -1) {
        employerContribution[gratuityIndex].yearly = Math.round(Number(gratuityYearly));
    }
    return employerContribution;
};

import dayjs from 'dayjs';

export function getAlphabeticValue(str: string): string {
    return str.replace(/[^a-zA-Z]/g, '');
}
export const disabledDate = (current: any) => {
    const oneWeekAgo = dayjs().subtract(7, 'days');
    return current && current < oneWeekAgo.startOf('day');
};
export const assetConfig = [
    { label: 'Basic', value: 'Basic' },
    { label: 'Intermediate', value: 'Intermediate' },
    { label: 'Advanced', value: 'Advanced' },
];
export const assetType = [
    { label: 'Laptop', value: 'Laptop' },
    { label: 'Desktop', value: 'Desktop' },
];
